<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id&expand=serviceNum"
    entity-name="服务类型"
    add-btn="新增服务类型"
    hide-show-detail
    hide-delete
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="{ name: 1 }"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="服务类型">
        <a-input v-model="form.name" placeholder="输入服务类型查询" :maxLength="10" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="服务类型" prop="name">
        <a-input v-model="form.name" placeholder="请输入服务类型（最多10字）" :maxLength="10" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:action="{ item }">
      <a class="txt-btn danger" @click.stop="delRow(item)" v-action:delete :class="{ grey: item.serviceNum > 0 }">
        删除
      </a>
    </template>
  </data-list>
</template>

<script>
export default {
  name: "ServiceType",
  data() {
    return {
      url: "/admin/volunteer-service-type",
      columns: [
        { title: "服务类型", dataIndex: "name" },
        {
          title: "关联服务数量",
          dataIndex: "serviceNum",
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入服务类型", trigger: "blur" }],
      },
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  methods: {
    delRow(item) {
      if (item.serviceNum > 0) {
        return;
      }
      this.$confirm({
        title: "提示",
        content: `确定删除此服务类型吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/${item.id}`,
            method: "DELETE",
          }).then(() => {
            this.$message.success("删除成功");
            this.updateList();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
